<template>
<div style="padding-top:-2px;margin-top:-2px;">
    <v-label><b>{{label}}</b></v-label>
    <vc-select-generic style="margin-top:-15px" autocomplete class="text" itemtext="CceDescription" itemvalue="CceID" :disabled="disabled" id="CceDescription" :value="value" :return-object="returnObject" @input="input($event)" :config="config" :full="full" :autofocus="autofocus" :text="text" :clearable="clearable" :noDefault="noDefault" :add="add" @add="addEvent()">
    </vc-select-generic>
</div>
</template>

<script>
import _sProjectsService from "@/services/Logistics/LgsGenericExactusService";
export default {
    components: {},
    props: {
        autofocus: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        value: null,
        text: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: "Centro Costo",
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        noDefault: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
        add: {
            type: Boolean,
            default: false,
        },
        business: {
            type: Number,
            default: 1,
        },
        areID: {
            type: Number,
            default: 0,
        },
    },
    watch: {

        value(value) {
            this.item = value;
        },

        business() {
            this.loadMain()
        }
    },
    methods: {
        load(item) {
            this.text = item.SupName
        },
        addEvent() {
            this.dialog = true;
        },
        input(val) {
            this.$emit("input", val);
        },
        loadMain() {
            
            this.config = {
                url: _sProjectsService.getCenterCoste(),
                params: {
                    search: '',
                    requestID: this.$fun.getUserID(),
                    business: this.business == null? 0 : this.business,
                    AreID: this.areID
                }
            
            }

            console.log("loadMain vccentercoste",this.config)
            
        }

    },
    data: () => ({
        dialog: false,
        item: 0,
        val: '',
        items: [],
        config: {},
    }),

    created() {
        this.loadMain()
    }
}
</script>
