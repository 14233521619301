<template>
<div style="padding-top:-2px;margin-top:-2px;">
    <v-label><b>{{label}}</b></v-label>
    <vc-select-generic style="margin-top:-15px" autocomplete class="text" itemtext="SptDescriptionLong" itemvalue="SptID" :disabled="disabled" id="SptDescriptionLong" :value="value" :return-object="returnObject" @input="input($event)" :config="config" :full="full" :autofocus="autofocus" :text="text" :clearable="clearable" :noDefault="noDefault" :add="add" @add="addEvent()" ref="selectTypeSpent">
    </vc-select-generic>
</div>
</template>

<script>
import _sTypeSpentService from "@/services/Logistics/LgsGenericExactusService";
export default {
    components: {},
    props: {
        autofocus: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        value: null,
        text: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: "Tipo Gasto",
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        noDefault: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
        add: {
            type: Boolean,
            default: false,
        },
        areID: {
            type: Number,
            default: 0,
        },
        type : {
            type: Number,
            default: 0,
        },
    },
    watch: {

        value(value) {
            this.item = value;
        },
        areID(newVal) {
            this.loadMain(); // Recargar datos cuando areID cambie
        },
        type(newVal) {
            this.loadMain(); // Recargar datos cuando type cambie
        }
    },
    methods: {
        load(item) {
            this.text = item.SupName
        },
        addEvent() {
            this.dialog = true;
        },
        input(val) {
            // this.$emit("input", val);
            if (this.returnObject) {
                this.$emit("input", val); // Emitir el objeto completo
            } else {
                this.$emit("input", val ? val.SptID : null); // Emitir solo el ID
            }
        },

        loadMain() {
            console.log("this.areIDssss--> ", this.areID);
            // console.log("props -> ", props);
            console.log("this.type--> ", this.type);
            this.config = {};
            this.config = {
                url: _sTypeSpentService.getTypeSpent(),
                params: {
                    search: '',
                    requestID: this.$fun.getUserID(),
                    AreID: this.areID,
                    type: this.type
                }
            }
            console.log("loadMain vctypespent--> ",this.config)
        }
    },
    data: () => ({
        dialog: false,
        item: 0,
        val: '',
        items: [],
        config: {
            url: '',
            params: {}
        },
    }),

    created() {
        // this.loadMain()
        if (this.areID && this.type !== undefined) {
            this.loadMain();
        }
    }
}
</script>
