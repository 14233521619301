<template>
<div>

    <div>
        <vc-header title="Lista de aprobaciones"></vc-header>
        <v-card class="pl-3 pr-3 pb-4 pt-3">
            <v-card elevation="2">
                <v-data-table class="elevation-0 scroll table-condensed table-bordered" disable-sort dense :items="itemsLevels" hide-default-footer :headers="headers" :items-per-page="-1">

                    <template v-slot:item.AtsStatusName="{item}">
                        <v-chip x-small :color="item.AtsStatusColor">
                            {{item.AtsStatusName}}</v-chip>
                    </template>

                </v-data-table>
            </v-card>
        </v-card>
    </div>
</div>
</template>

<script>
import _sApprovalTransaction from '@/services/Security/SecApprovalTransactionService';

export default {
    props: {
        DocEntry: null,
        TrsID: null,
        TrsCode: null
    },

    watch: {},

    data() {
        return {
            headers: [
                
                {
                    text: 'Nivel',
                    value: 'TypeLevelName',
                    /* width: 150 */
                },
                {
                    text: 'Cargo',
                    value: 'PstName',
                    /* width: 150 */
                },
                {
                    text: 'Persona',
                    value: 'NtpName',
                    /* width: 150 */
                },
                
                {
                    text: 'Estado',
                    value: 'AtsStatusName',
                    /* width: 150 */
                },
                {
                    text: 'Fecha',
                    value: 'AlvDateName',
                    /* width: 150 */
                },
                {
                    text: 'Observación',
                    value: 'AtsObservation'
                },
                {
                    text: 'Proceso',
                    value: 'AlvProcessName',
                    /* width: 150 */
                },
                {
                    text: 'TR (días)',
                    value: 'AlvDayNotifications',
                    width: 70,
                    align: "center"
                },
                {
                    text: 'TT [DD:HH:MM:SS]',
                    value: 'AlvTimeElapsedText',
                    width: 70
                }
            ],
            itemsLevels: [],
        }
    },

    watch: {
        DocEntry() {
            this.initialize()
        }
    },

    methods: {
        /* getStatusApprovalTransaction() {
            this.filter.DocEntry = this.operation.ReqID;
            this.filter.TrsID = this.operation.TrsID;

            _sApprovalTransaction
                .statusapprovaltransaction(this.filter, this.$fun.getUserID())
                .then(resp => {
                    if (resp.status == 200) {
                        this.itemsTransaction = resp.data.Result;
                    }
                })
        } */

        initialize() {
            if (this.DocEntry !== '' || this.DocEntry !== null)
                _sApprovalTransaction.statusapprovaltransaction({
                    DocEntry: this.DocEntry,
                    TrsID: this.TrsID,
                    TrsCode: this.TrsCode,
                    CpyID: this.$fun.getCompanyID() || 0
                }, this.$fun.getUserID()).then((r) => {
                    this.itemsLevels = r.data.Result;
                })

        }
    },

    created() {
        this.initialize()

    },
}
</script>
